<template>
  <div class="profile-wrapper emb-card pa-4">
    <h4>{{ $t("message.profilePage.profileInfo") }}</h4>
    <div class="pt-4">
      <div
        class="proflie-field mb-4"
        v-for="(info, key) in profileData"
        :key="key"
      >
        <label>{{ info.key }} :</label>
        <span>{{ info.value }}</span>
      </div>
      <div>
        <v-checkbox
            disabled
            color="accent"
            v-model="sms"
            :label="$t('message.signinPage.sms')"
                  ></v-checkbox>
      </div>
      <div>
        <a
          v-on:click="toogleUpdatePassword"
          class="text-decoration-underline black--text"
          >{{ $t("message.profilePage.changePassword") }}</a
        >
      </div>
      <div>
        <a
          v-on:click="toogleUpdateEmail"
          class="text-decoration-underline black--text"
          >{{ $t("message.profilePage.changeEmail") }}</a
        >
      </div>
      <v-banner class="grey lighten-4" v-if="updateEmailBanner">
        <v-flex xs12 sm12 md12 lg12 xl12>
          <v-layout row wrap>
            <v-flex xs8 sm8 md8 lg8 xl8>
              <v-text-field
                type="password"
                v-model.trim="currentPassword"
                :label="$t('message.profilePage.currentPasswordLabel')"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 sm12 md12 lg12 xl12>
          <v-layout row wrap>
            <v-flex xs8 sm8 md8 lg8 xl8>
              <v-form ref="form" v-model="valid">
                <v-text-field
                  :rules="emailRules"
                  v-model.trim="newEmail"
                  :label="$t('message.profilePage.newEmailLabel')"
                ></v-text-field>
              </v-form>
            </v-flex>
            <v-flex xs4 sm4 md4 lg4 xl4>
              <v-btn @click="updateUserEmail">{{
                $t("message.profilePage.update")
              }}</v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-banner>

      <v-banner class="grey lighten-4" v-if="updatePasswordBanner">
        <v-flex xs12 sm12 md12 lg12 xl12>
          <v-layout row wrap>
            <v-flex xs8 sm8 md8 lg8 xl8>
              <v-text-field
                type="password"
                v-model.trim="currentPassword"
                :label="$t('message.profilePage.currentPasswordLabel')"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 sm12 md12 lg12 xl12>
          <v-layout row wrap>
            <v-flex xs8 sm8 md8 lg8 xl8>
              <v-form ref="form" v-model="valid">
                <v-text-field
                  type="password"
                  :rules="passwordRules"
                  v-model.trim="newPassword"
                  :label="$t('message.profilePage.newPasswordLabel')"
                ></v-text-field>
              </v-form>
            </v-flex>
            <v-flex xs4 sm4 md4 lg4 xl4>
              <v-btn @click="updatePassword">{{
                $t("message.profilePage.update")
              }}</v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-banner>

      <router-link :to="{ name: 'EditProfileInfo', query: { type: 'info' } }">
        <v-btn class="accent mx-0 mt-4">{{ $t("message.edit") }}</v-btn>
      </router-link>
    </div>
    <v-snackbar v-model="snackbar" color="red">
      {{ errorMsg(errorCode) }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
  updateEmail,
} from "firebase/auth";
import { getFirestore, doc, updateDoc } from "firebase/firestore";
export default {
  data() {
    return {
      valid: false,
      snackbar: false,
      errorCode: "",
      profileData: [],
      updatePasswordBanner: false,
      updateEmailBanner: false,
      newPassword: "",
      newEmail: "",
      sms: true,
      currentPassword: "",
      passwordRules: [
        (v) => !!v || this.$t("message.signinPage.error.passwordRequired"),
        (v) =>
          v.length >= 6 ||
          this.$t("message.signinPage.error.auth/weak-password"),
        (v) =>
          (v && /^[A-Za-z\d@$!%*?&#()+=-_]{6,}$/.test(v)) ||
          this.$t("message.signinPage.error.auth/accepted-char"),
      ],
      emailRules: [
        (v) => !!v || this.$t("message.signinPage.error.emailRequired"),
        (v) =>
          /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) ||
          this.$t("message.signinPage.error.auth/invalid-email"),
      ],
    };
  },
  computed: {
    ...mapGetters([
      "getUser",
      "getUserInfo",
      "getUserPrivateInfo",
      "currencies",
    ]),
  },
  watch: {
    "$store.state.userPrivateInfo": function () {
      this.updateData();
    },
    "$store.state.userInfo": function () {
      this.updateData();
    },
  },
  methods: {
    updateData() {
      this.profileData = [];
      if (this.getUserInfo != null) {
        var publicUserInfo = [
          {
            key: this.$t("message.profilePage.firstName"),
            value: this.getUserInfo["firstname"],
          },
          {
            key: this.$t("message.profilePage.lastName"),
            value: this.getUserInfo["lastname"],
          },
          {
            key: this.$t("message.profilePage.dob"),
            value: this.getUserInfo["dob"].toDate().toISOString().substr(0, 10),
          },
          {
            key: this.$t("message.profilePage.phone"),
            value: this.getUserInfo["phone"],
          },
          {
            key: this.$t("message.profilePage.email"),
            value: this.getUserInfo["email"],
          },
          {
            key: this.$t("message.profilePage.language"),
            value: this.$t("message.language." + this.getUserInfo["language"]),
          },
          // {
          //   key: this.$t("message.profilePage.currency"),
          //   value: this.currencies[this.getUserInfo["currency"]].title,
          // },
        ];
        this.profileData = this.profileData.concat(publicUserInfo);
        if (this.getUserInfo.sms != undefined) {
          this.sms = this.getUserInfo.sms;
        }
      }
      if (this.getUserPrivateInfo != null) {
        var privateUserInfo = [
          {
            key: this.$t("message.profilePage.credit"),
            value: this.$root.$formatMoney(this.getUserPrivateInfo.credit),
          },
        ];
        this.profileData = this.profileData.concat(privateUserInfo);
      }
    },
    toogleUpdatePassword() {
      this.updatePasswordBanner = !this.updatePasswordBanner;
      this.newPassword = "";
      this.currentPassword = "";
    },
    toogleUpdateEmail() {
      this.updateEmailBanner = !this.updateEmailBanner;
      this.newEmail = "";
      this.currentPassword = "";
    },
    errorMsg(code) {
      if (code != "") {
        return this.$t("message.signinPage.error." + code);
      } else {
        return "";
      }
    },
    updateUserEmail() {
      this.$refs.form.validate();
      if (this.valid) {
        const cred = EmailAuthProvider.credential(
          this.getUser.email,
          this.currentPassword
        );

        reauthenticateWithCredential(this.getUser, cred)
          .then(() => {
            updateEmail(this.getUser, this.newEmail)
              .then(() => {
                const updatedDoc = {
                  email: this.newEmail,
                };
                updateDoc(
                  doc(getFirestore(), "users", this.getUser.uid),
                  updatedDoc
                ).then(() => {
                  this.updateEmailBanner = false;
                  this.errorCode = "";
                  this.$snotify.success(
                    this.$t("message.profilePage.emailChanged"),
                    {
                      closeOnClick: false,
                      pauseOnHover: false,
                      timeout: 1000,
                    }
                  );
                });
                this.$store
                  .dispatch("fetch_user_info", this.getUser.uid)
                  .then(() => {
                    this.$router.push("/home");
                  });
              })
              .catch((error) => {
                console.log(error);
                if (error.code != "") {
                  this.snackbar = true;
                  this.errorCode = error.code;
                }
              });
          })
          .catch((error) => {
            console.log(error);
            if (error.code != "") {
              this.snackbar = true;
              this.errorCode = error.code;
            }
          });
      }
    },
    updatePassword() {
      this.$refs.form.validate();
      if (this.valid) {
        const cred = EmailAuthProvider.credential(
          this.getUser.email,
          this.currentPassword
        );
        reauthenticateWithCredential(this.getUser, cred)
          .then(() => {
            updatePassword(this.getUser, this.newPassword)
              .then(() => {
                this.updatePasswordBanner = false;
                this.errorCode = "";
                this.$snotify.success(
                  this.$t("message.profilePage.passwordChanged"),
                  {
                    closeOnClick: false,
                    pauseOnHover: false,
                    timeout: 1000,
                  }
                );
              })
              .catch((error) => {
                if (error.code != "") {
                  this.snackbar = true;
                  this.errorCode = error.code;
                }
              });
          })
          .catch(() => {
            this.snackbar = true;
            this.errorCode = "currentPassword";
          });
      }
    },
  },
  beforeMount() {
    this.updateData();
  },
};
</script>